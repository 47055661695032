var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "config-image-web-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c("a-breadcrumb-item", [_vm._v("系统设置")]),
                _c("a-breadcrumb-item", [_vm._v("平台配置")]),
                _c("a-breadcrumb-item", [_vm._v("基础配置")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-card",
            { attrs: { title: "1. 基本信息" } },
            [_c("jtl-form", { ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME })],
            1
          ),
          _c("br"),
          _c(
            "a-card",
            { staticClass: "color_card", attrs: { title: "2. 颜色主题" } },
            [
              _c(
                "div",
                { staticClass: "color_card_theme" },
                [
                  _c("span", [_vm._v("默认风格 :  ")]),
                  _vm._l(_vm.theme, function (item, index) {
                    return _c(
                      "span",
                      {
                        key: item.type,
                        staticClass: "color_card_list",
                        style: {
                          background: item.type === "dark" ? "#000" : "#EFEFEF",
                          color: item.type === "dark" ? "#fff" : "#000",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.switchTheme(index)
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(item.name) + " "),
                        item.checked
                          ? _c("a-icon", {
                              staticClass: "color_card_item_icon",
                              style: {
                                color:
                                  item.type === "dark" ? "#fff" : "#2393cd",
                              },
                              attrs: { type: "check" },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "color_card_primary" },
                [
                  _c("span", [_vm._v("   主题色 :  ")]),
                  _vm._l(_vm.primary, function (item, index) {
                    return _c(
                      "span",
                      {
                        key: item.type,
                        staticClass: "color_card_list",
                        style: { background: item.type },
                        on: {
                          click: function ($event) {
                            return _vm.switchPrimary(index)
                          },
                        },
                      },
                      [
                        item.checked
                          ? _c("a-icon", {
                              staticClass: "color_card_item_icon",
                              staticStyle: { color: "#fff" },
                              attrs: { type: "check" },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "save-button p-20",
                  staticStyle: { "margin-left": "120px" },
                },
                [
                  _c(
                    "jtl-button",
                    {
                      attrs: {
                        type: "primary",
                        "click-prop": _vm.saveClick.bind(this),
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c("br"),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }